import React from 'react'
import useFooterData from '../../hooks/footer-menu'
import useSiteMetadata from '../../hooks/use-site-meta'
import {useMenus} from "../../hooks/use-menus";
import {useFooterCta} from "../../hooks/use-footer-cta";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import logo from '../../images/logo-procopio.svg'
import Logo from '../Logo'
import CallToAction from '../CallToAction'
import Link from '../Link'
import {
	footer,
	cta,
	logoMobile,
	menu,
	social,
	legal,
	privacy
} from './Footer.module.scss'

const Footer = ({  }) => {
	const footerData = useFooterData()
	const legalMenu = useMenus('legal')
	const metaData = useSiteMetadata()
	const ctaData = useFooterCta()

	const footerNav = footerData.wpMenu?.menuItems?.nodes
	const copyright = metaData?.site?.siteMetadata?.copyright
	const socialLinks = metaData?.site?.siteMetadata?.social

	return (
		<>
			<footer className={footer}>
			<Container fluid="lg">
				<div>
					<div className={['d-lg-none', 'd-flex', logoMobile].join(' ')}>
						<Link className="mx-auto d-inline-block" to="/" aria-label="Procopio">
							<Logo logoSrc={logo} width={121} height={27} padding={0} />
						</Link>
					</div>
				</div>
				<div className="justify-content-sm-center">
					<div className={cta}>
						<CallToAction
							headingText={ ctaData?.headline ?? "Partner with us today." }
							buttonText={ ctaData?.ctaText ?? "Partner with Procopio"  }
							href={ ctaData?.ctaLink ?? "/contact" }
						/>
					</div>
				</div>
				<Row className="d-flex flex-column flex-lg-row flex-row">
					<Col className="d-none d-lg-flex" lg={4}>
						<Link to="/">
							<Logo logoSrc={logo} width={121} height={27} padding={0} alt/>
						</Link>
					</Col>
					<Col lg={4}>
						<div className={menu}>
							{footerNav &&
							footerNav.map(v => (
								<Link key={v.label} to={v.path}>
									{v.label}
								</Link>
							))}
						</div>
					</Col>
					<Col lg={4}>
						<div
							className={[
								'text-xs-center',
								'text-lg-right',
								'mx-auto',
								social,
							].join(' ')}
						>
							{socialLinks &&
								socialLinks.map(v => (
								<Link
									key={v.label}
									to={v.path}
									target="_blank"
									label={`Connect with us on ${v.label} (opens in a new tab).`}
								>
									<i className={`fa-brands ${v.icon}`} />
								</Link>
							))}
						</div>
					</Col>
				</Row>
				<hr className="mt-3" />
				<div className={legal}>
					<div className={privacy}>
						&copy; {new Date().getFullYear()} {copyright}
					</div>
					<br />

					{legalMenu[0]?.menu && (
						<>
							{legalMenu[0]?.menu?.menuItems?.nodes.map((item, index) => (
								<Link to={item.url} key={`legal-menu-item-${index}`} target={item.target ?? null}>
									{item.label}
								</Link>
								)
							)}
						</>
					)}

					<a href="https://mindgruve.com/" target="_blank" rel="nofollow noopener">Site by Mindgruve <span className="sr-only">(opens in a new tab)</span></a>
				</div>
			</Container>
			</footer>
		</>
	)
}

export default Footer
